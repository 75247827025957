import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {store } from '../../redux/store/initStore';

const StateListener = () => {
  const state = useSelector((state) => state);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      //console.log("El estado de Redux ha cambiado:", store.getState());
      // Aquí puedes realizar acciones adicionales en función de los cambios en el estado.
    });

    return () => {
      unsubscribe(); // Limpieza: cancela la suscripción cuando el componente se desmonta.
    };
  }, []);

  return null; // No renderiza ningún contenido en el DOM.
};

export default StateListener;