import { useEffect } from "react";

const checkPersistence = () => {
  navigator.storage?.persisted()
    .then((isPersistent) => {
      if (!isPersistent) {
        requestPersistence();
      } else {
        console.log("Persistent storage: Was Enabled");
      }
    })
    .catch((e) => {
      console.error("Persistent storage: Error", e);
    });
};

const requestPersistence = () => {
  navigator.storage?.persist().then((granted) => {
    console.log("Granted", granted);
    console.log(`Persistent storage: ${granted ? "Enabled" : "Denied"}`);
  });
};

const usePersistentStorage = () => {
  useEffect(() => {
    console.log("usePersistentStorage hook invoked");

    // Add event listeners for user interaction
    window.addEventListener('click', checkPersistence, { once: true });
    window.addEventListener('touchstart', checkPersistence, { once: true });

    // Cleanup the event listeners on unmount
    return () => {
      window.removeEventListener('click', checkPersistence);
      window.removeEventListener('touchstart', checkPersistence);
    };
  }, []);
};

export default usePersistentStorage;