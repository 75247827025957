/* eslint-disable no-useless-escape */
const REPORT_CATEGORIES = {
  alarm: 'Alarm',
  backflow: 'Backflow',
  emergency: 'Emergency Exit/Light',
  extinguiser: 'Extinguisher',
  fireDoor: 'Fire Door',
  greaseCleaning: 'Grease Cleaning',
  hoodSystem: 'Hood System',
  hydrant: 'Hydrant',
  monitoring: 'Monitoring',
  pump: 'Pump',
  security: 'Security',
  sensitivity: 'Sensitivity',
  specialHazard: 'Special Hazard',
  sprinkler: 'Sprinkler',
  standpipeHose: 'Standpipe/Hose',
  suppression: 'Suppression',
  waterTank: 'Water Tank',
  other: 'Other',
};

export const transactions = 'transactions';
export const inventoryTransactionTypes = {
  added: 'Added',
  activated: 'Activated',
  archived: 'Archived',
  unarchived: 'Unarchived',
  deleted: 'Deleted',
  assigned: 'Assigned',
  unassigned: 'Unassigned',
  backInStock: 'Back in Stock',
  outOfStock: 'Out of Stock',
};

export const transactionfilterHeads = ['Asset Type', 'Transaction Type'];

export const inventoryAssets = {
  part: 'Part',
  tool: 'Tool',
  vehicle: 'Vehicle',
  supplier: 'Supplier',
};

const popUpStyle = 'width=560,height=340,toolbar=0,menubar=0,location=0';
const EndMessageDeficiency = 'No Deficiencies Found';
const EndMessageDeficiencyPagination = 'No More Deficiencies Found';
const endMessageForTaskTable = 'No jobs found';
const endMessageTaskPagination = 'No more jobs found';
const endMessageTransaction = 'No more transactions found';
const endMessageForTransactionTable = 'No transactions found';
const endMessageForReportTable = 'No report found';
const endMessageReportPagination = 'No more reports found';
const successMsgForChangePassword = 'Password updated successfully';
const updationMessage = 'Successfully updated team member';
const deletionMessage = 'Successfully deleted team member';
const employeeAddMessage = 'Successfully added team member';
const validationErrorMessage = 'Please check for any validation error!';
const datesErrorMsg = 'Dates can be selected of a week';
const customerRole = 'CUSTOMER';
const businessName = 'businessName';
const accountId = 'accountId';
const Customer = 'Customers';
const validateErrMsg = 'Please fill above details before assigning task';
const Quarterly = 'QUARTELY';
const noImage = 'No images available';
const noFeasiblePath = 'No feasible path available.';
const wrongAddress = 'Address is not found on map.';
const ZERO_RESULTS = 'ZERO_RESULTS';
const NOT_FOUND = 'NOT_FOUND';
const limit = 100;
const autoRoutingConstants = {
  date: 'date',
  teamMember: 'teamMember',
  startingLocation: 'startingLocation',
  reportCategory: 'reportCategory',
  timeCategory: 'timeCategory',
  dueThisMonth: 'dueThisMonth',
  missedInspections: 'missedInspections',
  'Select Schedule Date*': 'Select Schedule Date*',
};
const selectedJobError = 'This job has been selected before.';
const switchToOfflineMsg = 'Switch to offline mode';
const waitMsgWhileDownloading = 'Please wait while downloading...';
const customerAlreadsyDownloaded = 'Customer already downloaded.';
const goToOfflineMsg = 'To go to offline mode click on the button.';
const jobSelectionMsgLimit =
  'Maximum 10 jobs can be selected for offline mode.';
const deleteMsgForSelectedOfflineJob =
  'Selected job for offline mode removed successfully.';

const STATUSES = {
  pending: 'Pending',
  jobCreated: 'Job Created',
  // completed: 'Completed',  // TODO: add filter once api starts taking it in payload
};
const REPORT_TYPES_LABELS = ['All', 'Inspection', 'Quote', 'Work Order'];

const curentLocation = {
  latitude: 40.7128,
  longitude: -74.006,
};

const REPORT_FILTER_LIST_HEADER = [
  'Inspections',
  'Work Orders',
  'Quotes',
  'Incomplete Reports',
];

export const REPORT_FILTER_LIST_HEADER_FOR_EXTINGUISHER = [
  'Inspections',
  'Work Orders',
  'Quotes',
  'Services Due',
  'Incomplete Reports',
];

const offlineSelectedJobTableHeader = [
  {
    title: 'Report Category',
    isSortable: false,
  },
  {
    title: 'Job Name',
    isSortable: false,
  },
  {
    title: 'Buisness Name',
    isSortable: false,
  },
  {
    title: 'Downloaded At',
    isSortable: false,
  },
  {
    title: ' ',
    isSortable: false,
  },
];

const SORT = {
  ASCENDING: 1,
  DESCENDING: -1,
};

const INITIAL_PLAN_ID = {
  PRICE: 19900,
  PLAN_ID: 'plan_FenaSrPuYuwXD0',
  NICK_NAME: 'Standard',
};

const WEB = {
  webApp: 'web',
};

const QUESTION_IS_PRESENT = {
  questionPresent: 'Question already exists',
};

const DELETE_NOTES = {
  notesDelete: 'Building info deleted successfully',
};

export const convertFrequency = {
  Weekly: 'WEEKLY',
  WEEKLY: 'Weekly',
  BI_WEEKLY: 'Bi Weekly',
  'Bi Weekly': 'BI_WEEKLY',
  MONTHLY: 'Monthly',
  Monthly: 'MONTHLY',
  BI_MONTHLY: 'Bi Monthly',
  'Bi Monthly': 'BI_MONTHLY',
  QUARTELY: 'Quarterly',
  Quarterly: 'QUARTELY',
  SEMI_ANNUAL: 'Semi Annual',
  'Semi Annual': 'SEMI_ANNUAL',
  ANNUAL: 'Annual',
  Annual: 'ANNUAL',
  BI_ANNUAL: 'Bi Annual',
  'Bi Annual': 'BI_ANNUAL',
  THREE_YEAR: '3 year',
  '3 year': 'THREE_YEAR',
  FIVE_YEAR: '5 year',
  '5 year': 'FIVE_YEAR',
};

export const RECURRING_INSPECTION_PRICE_FIELDS = {
  WEEKLY: {
    label: 'Weekly',
    count: ['1st'],
  },
  BI_WEEKLY: {
    label: 'Bi Weekly',
    count: ['1st'],
  },
  MONTHLY: {
    label: 'Monthly',
    count: ['1st'],
  },
  BI_MONTHLY: {
    label: 'Bi Monthly',
    count: ['1st', '2nd', '3rd', '4th', '5th', '6th'],
  },
  QUARTELY: {
    label: 'Quarterly',
    count: ['1st', '2nd', '3rd', '4th'],
  },
  SEMI_ANNUAL: {
    label: 'Semi Annual',
    count: ['1st', '2nd'],
  },
  ANNUAL: {
    label: 'Annual',
    count: ['1st'],
  },
  BI_ANNUAL: {
    label: 'Bi Annual',
    count: ['1st'],
  },
  THREE_YEAR: {
    label: '3 year',
    count: ['1st'],
  },
  FIVE_YEAR: {
    label: '5 year',
    count: ['1st'],
  },
};

const RECURRING_INSPECTION_VALID_FREQUENCIES = [
  {
    value: 'WEEKLY',
    label: 'Weekly',
  },
  {
    value: 'BI_WEEKLY',
    label: 'Bi Weekly',
  },
  {
    value: 'MONTHLY',
    label: 'Monthly',
  },
  {
    value: 'BI_MONTHLY',
    label: 'Bi Monthly',
  },
  {
    value: 'QUARTELY',
    label: 'Quarterly',
  },
  {
    value: 'SEMI_ANNUAL',
    label: 'Semi Annual',
  },
  {
    value: 'ANNUAL',
    label: 'Annual',
  },
  {
    value: 'BI_ANNUAL',
    label: 'Bi Annual',
  },
  {
    value: 'THREE_YEAR',
    label: '3 year',
  },
  {
    value: 'FIVE_YEAR',
    label: '5 year',
  },
];

export const frequencyForParity = ['Bi Weekly', 'Bi Monthly', 'Bi Annual'];

const MONTH_LIST = [
  {
    value: 'JANUARY',
    label: 'January',
    shortLabel: 'Jan',
  },
  {
    value: 'FEBRUARY',
    label: 'February',
    shortLabel: 'Feb',
  },
  {
    value: 'MARCH',
    label: 'March',
    shortLabel: 'Mar',
  },
  {
    value: 'APRIL',
    label: 'April',
    shortLabel: 'Apr',
  },
  {
    value: 'MAY',
    label: 'May',
    shortLabel: 'May',
  },
  {
    value: 'JUNE',
    label: 'June',
    shortLabel: 'Jun',
  },
  {
    value: 'JULY',
    label: 'July',
    shortLabel: 'Jul',
  },
  {
    value: 'AUGUST',
    label: 'August',
    shortLabel: 'Aug',
  },
  {
    value: 'SEPTEMBER',
    label: 'September',
    shortLabel: 'Sept',
  },
  {
    value: 'OCTOBER',
    label: 'October',
    shortLabel: 'Oct',
  },
  {
    value: 'NOVEMBER',
    label: 'November',
    shortLabel: 'Nov',
  },
  {
    value: 'DECEMBER',
    label: 'December',
    shortLabel: 'Dec',
  },
];

const RECURRING_INSPECTION_PARITY = [
  {
    label: 'Even',
    value: 'EVEN',
  },
  {
    label: 'Odd',
    value: 'ODD',
  },
];

const VALID_PHONE_NUMBERS = [
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{0,20}\\)[ \\-]*)|([0-9]{0,20})[ \\-]*)*?[0-9]{0,20}?[ \\-]*[0-9]{0,24}?$/,
];

const VALID_ZIP_CODE_REGEX_EXPRESSIONS = [
  /^\d{5}([\-]?\d{4})?$/,
  /^(GIR|[A-Z]\d[A-Z\d]??|[A-Z]{2}\d[A-Z\d]??)[ ]??(\d[A-Z]{2})$/,
  /\b((?:0[1-46-9]\d{3})|(?:[1-357-9]\d{4})|(?:[4][0-24-9]\d{3})|(?:[6][013-9]\d{3}))\b/,
  /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ])\ {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/,
  /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
  /^(V-|I-)?[0-9]{5}$/,
  /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/,
  /^[1-9][0-9]{3}\s?([a-zA-Z]{2})?$/,
  /^([1-9]{2}|[0-9][1-9]|[1-9][0-9])[0-9]{3}$/,
  /^([D|d][K|k]( |-))?[1-9]{1}[0-9]{3}$/,
  /^(s-|S-){0,1}[0-9]{3}\s?[0-9]{2}$/,
  /^[1-9]{1}[0-9]{3}$/,
  /^\d{6}$/,
];

export const PART_STATUS = {
  'In Stock': 'In Stock',
  'Out of Stock': 'Out of Stock',
};

export const accountTypes = ['MASTER', 'SUB', 'BUILDING'];

export const parentFieldNames = ['customerId', 'masterId', 'subId'];

export const staticRowHeaderFields = [
  {
    title: 'Company Name',
    isSortable: true,
  },
  {
    title: 'ID/Account',
    isSortable: true,
  },
  {
    title: 'Address',
    isSortable: false,
  },
  {
    title: 'Phone',
    isSortable: false,
  },
  {
    title: 'Account Type',
    isSortable: false,
  },
];

export const reportCategoriesWithRoutesForMenu = {
  EmergencyExit: 'EmergencyExit',
  Alarm: REPORT_CATEGORIES.alarm,
  Pump: REPORT_CATEGORIES.pump,
  Backflow: REPORT_CATEGORIES.backflow,
  Security: REPORT_CATEGORIES.security,
  Extinguisher: REPORT_CATEGORIES.extinguiser,
  SpecialHazard: 'SpecialHazard',
  FireDoor: 'FireDoor',
  Sprinkler: REPORT_CATEGORIES.sprinkler,
  GreaseCleaning: 'GreaseCleaning',
  Standpipe: 'Standpipe',
  HoodSystem: 'HoodSystem',
  Suppression: REPORT_CATEGORIES.suppression,
  Hydrant: REPORT_CATEGORIES.hydrant,
  WaterTank: 'WaterTank',
  Monitoring: REPORT_CATEGORIES.monitoring,
  Sensitivity: REPORT_CATEGORIES.sensitivity,
  Other: REPORT_CATEGORIES.other,
  FirePump: 'Fire Pump',
  WorkOrder: 'Work Order',
  Quote: 'Quote',
};

export const reportCategoriesWithRoutes = {
  EmergencyExit: REPORT_CATEGORIES.emergency,
  Alarm: REPORT_CATEGORIES.alarm,
  Pump: REPORT_CATEGORIES.pump,
  Backflow: REPORT_CATEGORIES.backflow,
  Security: REPORT_CATEGORIES.security,
  Extinguisher: REPORT_CATEGORIES.extinguiser,
  SpecialHazard: REPORT_CATEGORIES.specialHazard,
  FireDoor: REPORT_CATEGORIES.fireDoor,
  Sprinkler: REPORT_CATEGORIES.sprinkler,
  GreaseCleaning: REPORT_CATEGORIES.greaseCleaning,
  Standpipe: REPORT_CATEGORIES.standpipeHose,
  HoodSystem: REPORT_CATEGORIES.hoodSystem,
  Suppression: REPORT_CATEGORIES.suppression,
  Hydrant: REPORT_CATEGORIES.hydrant,
  WaterTank: REPORT_CATEGORIES.waterTank,
  Monitoring: REPORT_CATEGORIES.monitoring,
  Sensitivity: REPORT_CATEGORIES.sensitivity,
  Other: REPORT_CATEGORIES.other,
  FirePump: 'Fire Pump',
  WorkOrder: 'Work Order',
  Quote: 'Quote',
};

export const resourceClasses = {
  driving: {
    grandParentClass: 'driving-parent',
    parentClass: 'driving-icon',
    childClass: 'driving-child',
  },
  break: {
    grandParentClass: 'break-parent',
    parentClass: 'break-icon',
    childClass: 'break-child',
  },
  miscellaneous: {
    grandParentClass: 'miscellaneous-parent',
    parentClass: 'miscellaneous-icon',
    childClass: 'miscellaneous-child',
  },
  checkout: {
    grandParentClass: 'checkout-parent',
    parentClass: 'checkout-icon',
    childClass: 'checkout-child',
  },
  working: {
    grandParentClass: 'working-parent',
    parentClass: 'working-icon',
    childClass: 'working-child',
  },
};

export const STATUS = [
  'working',
  'break',
  'checkout',
  'miscellaneous',
  'driving',
];

export const roles = ['CUSTOMER', 'EMPLOYEE'];

export const CUSTOMER_ROLE = {
  CUSTOMER: roles[0],
  EMPLOYEE: roles[1]
}

export const userStatuses = ['TRIAL', 'FREETRIAL', 'SUBSCRIBED', 'CANCELLED'];

export const customerSubMenuForToolTips = {
  customerInfo: 'Customer Info',
  ownerInfo: 'Owner/Agent Info',
  buildingInfo: 'Building Info',
  uploadDevices: 'Import Devices',
  recurringInspections: 'Recurring Inspections',
  deficiencies: 'Deficiencies',
  customerPortal: 'Customer Portal',
  addJob: 'Add Job',
  autoEmail: 'Auto Email',
  systemInfo: 'System Info',
  images: 'Images',
  Dashboard: 'Dashboard',
  Customers: 'Customers',
  Team: 'Team',
  JobLink: 'JobLink',
  Reports: 'Reports',
  Jobs: 'Jobs',
  'Service Requests': 'Service Requests',
  Inventory: 'Inventory',
  'Bug Tickets': 'Bug Tickets',
  GPS: 'GPS',
  TimeCard: 'TimeCard',
  New: 'New',
  Completed: 'Completed',
  Incomplete: 'Incomplete',
  'My Jobs': 'My Jobs',
  'Jobs Due': 'Jobs Due',
  'Jobs Missed': 'Jobs Missed',
  Parts: 'Parts',
  Tools: 'Tools',
  'Inventory on Vehicles': 'Vehicle Inventory',
  Transactions: 'Transactions',
  Suppliers: 'Suppliers',
  Subscriptions: 'Subscriptions',
  'QR Code': 'QR Code',
  Videos: 'Videos',
  'Company Logo': 'Company Logo',
  'Manage Report': 'Manage Report',
};

export const customerSubMenu = {
  customer: 'Customers',
  home: 'Home',
  customerInfo: 'Customer Info',
  ownerInfo: 'Owner/Agent',
  buildingInfo: 'Building Info',
  uploadDevices: 'Upload Devices',
  recurringInspections: 'Recurring Inspections',
  deficiencies: 'Deficiencies',
  customerPortal: 'Customer Portal',
  addJob: 'Add Job',
  autoEmail: 'Auto Email',
  systemInfo: 'System Info',
  images: 'Images',
  ...reportCategoriesWithRoutes,
  Light: 'Light',
  Hose: 'Hose',
};

export const subMenuItems = {
  team: 'Team',
  reports: 'Reports',
  task: 'Jobs',
  myJobs: 'My Jobs',
  inventory: 'Inventory',
  settings: 'Settings',
  jobLink: 'JobLink',
  serviceRequests: 'Service Requests',
  Deficiencies: 'Deficiencies',
  bugTickets: 'Bug Tickets',
  timeCard: 'Time Card',
  gps: 'GPS',
  new: 'New',
  view: 'View',
  recent: 'Recent',
  incomplete: 'Incomplete',
  dueThisMonth: 'Jobs Due',
  missedInspections: 'Jobs Missed',
  part: 'Parts',
  tools: 'Tools',
  inventoryOnVehicles: 'Vehicle Inventory',
  suppliers: 'Suppliers',
  subscriptions: 'Subscriptions',
  qrCode: 'QR Code',
  videos: 'Videos',
  companyLogo: 'Company Logo',
  manageReport: 'Manage Report',
  reportClientDetail: 'New',
};

const INSPECTION_FIELD_NAMES = {
  frequency: 'frequency',
  months: 'months',
  parity: 'parity',
  isTaskAdded: 'isTaskAdded',
  Jan: 'Jan',
  Feb: 'Feb',
  Mar: 'Mar',
  Apr: 'Apr',
  May: 'May',
  Jun: 'Jun',
  Jul: 'Jul',
  Aug: 'Aug',
  Sept: 'Sept',
  Oct: 'Oct',
  Nov: 'Nov',
  Dec: 'Dec',
};

export {
  REPORT_CATEGORIES,
  INSPECTION_FIELD_NAMES,
  EndMessageDeficiencyPagination,
  REPORT_TYPES_LABELS,
  STATUSES,
  SORT,
  INITIAL_PLAN_ID,
  popUpStyle,
  WEB,
  customerRole,
  endMessageForTransactionTable,
  endMessageTransaction,
  EndMessageDeficiency,
  endMessageForTaskTable,
  endMessageTaskPagination,
  endMessageForReportTable,
  endMessageReportPagination,
  successMsgForChangePassword,
  updationMessage,
  deletionMessage,
  employeeAddMessage,
  QUESTION_IS_PRESENT,
  VALID_ZIP_CODE_REGEX_EXPRESSIONS,
  validationErrorMessage,
  DELETE_NOTES,
  datesErrorMsg,
  VALID_PHONE_NUMBERS,
  accountId,
  businessName,
  Customer,
  RECURRING_INSPECTION_VALID_FREQUENCIES,
  MONTH_LIST,
  RECURRING_INSPECTION_PARITY,
  validateErrMsg,
  Quarterly,
  noImage,
  REPORT_FILTER_LIST_HEADER,
  curentLocation,
  wrongAddress,
  noFeasiblePath,
  ZERO_RESULTS,
  NOT_FOUND,
  limit,
  autoRoutingConstants,
  selectedJobError,
  offlineSelectedJobTableHeader,
  deleteMsgForSelectedOfflineJob,
  jobSelectionMsgLimit,
  customerAlreadsyDownloaded,
  waitMsgWhileDownloading,
  goToOfflineMsg,
  switchToOfflineMsg,
};

export const googleMaps = 'https://www.google.com/maps/search/';

export const Administrative_Privileges = {
  Full_Administrative_Privileges: 'Full Administrative Privileges',
};

export const priceAddedSuccessfully = 'Price added successfully';

export const PART_INFO_STATUS = {
  Archived: 'Archive',
  'In Stock': 'In Stock',
  'Out of Stock': 'Out of Stock',
};

export const durationTypes = ['Hours', 'Date'];

export const reportFilters = {
  inspections: 'Inspections',
  workOrder: 'Work Orders',
  quote: 'Quotes',
  serviceDue: 'Services Due',
  serviceDone: 'Services Done',
  incompleteReport: 'Incomplete Reports',
  workorderMessage: 'Work Order History',
  quoteMessage: 'Quote History',
  inspectionMessage: 'Report History',
  serviceMessage: 'Service Due History',
};

export const questionRecurrency = [
  {value: "ONE_TIME", label: 'This one time'},
  {value: "EVERY_TIME_THIS_CUSTOMER", label: 'Every time for this customer'},
  {value: "EVERY_TIME_THIS_TYPE", label: 'Every time of this report type on all customers'}
]

export const questionRecurrencyEnum = () => {
  let enumObj = {}
  questionRecurrency.forEach( ({value, label}) => {
    enumObj[value] = value
  })
  return enumObj
}

export const locationPermissionTypes = {
  GRANTED: 'granted',
  PROMPT: 'prompt',
  DENIED: 'denied',
  TIMEOUT: 'timeout',
}

export const locationConfig = {
  timeout: 15000, 
  maximumAge: 1000
}

export const yearMonthFormat = 'YYYY MMM';

export const dateFormat = "YYYY-MM-DD";

export const yearMonthPickerFormat = "YYYY MM"

export const drySystemQuestionsIds = [
  'foamWasPrimingWaterLevelChecked',
  'foamWasLowAirAlarmTested',
  'foamLowTemperatureAlarmWasInspectedDuringColdWeather',
  'foamDoesGaugeOnTheSupplySideOfValveReadNormalPSI',
  'foamDoesGaugeOnTheSystemSideOfDryValveReadPoperRatioPSI',
  'foamDoesGaugeOnTheQuickOperningDeviceReadSameAsSystemSidePSI'
]

export const signatureQuestionIds = {
  OWNER: "finishReportOwnerSignature",
  INSPECTOR: "finishReportInspectorSignature"
}

export const defaultMissedQuestionMsg = "Please finish answering all questions"

export const defaultLanguage = "en"

export const defaultMonthPickerFormat = "MM-yyyy"

export const pickerPrettyDateFormats = {
  en: 'MMMM dd, yyyy h:mm aa',
  es: 'dd MMMM, yyyy h:mm aa',
}

export const pickerDateFormats = {
  en: 'MM-DD-YYYY',
  es: 'DD-MM-YYYY',
}

export const monthsAbbreviation = {
  en: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
  ],
  es: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
  ]
}

export const prettyMonthDateFormats = {
  /** @property - The english version of */
  en: 'MMM D YYYY',
  es: 'D MMM YYYY',
}

export const countryNames = {
  'US': 'united states',
}

export const modifiedReports = []
export const targetQuestionIds = ["controlPanelPanelType", "panelType"]
export const affectedSections = ["controlPanel", "panel"]
export const oldOptionLabel = 'Sub'
export const newOptionLabel = 'Sub Panel'

export const successfullyAttached = "Document attached successfully"