import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Provider } from 'react-redux';
import { initDB } from 'react-indexed-db';
import { BrowserRouter } from 'react-router-dom';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Formio, Components, WebformBuilder } from 'react-formio';

import ErrorBoundary from 'components/ErrorBoundary';
import UserConfirmation from 'components/UserConfirmation';
import { version } from '../package.json';
import { persistor, store } from './redux/store/initStore';
import App from './App';
import IndexedDBconfig from './DBConfig';
import setupAriesComponents from './FormioSetup';
import { AppConfig } from './FormioAppConfig';
import ChatProvider from './components/ChatProvider';
import config from './config';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../public/assets/styles/global.css';
import '../public/assets/styles/custom.css';
import '../public/assets/styles/customer.css';
import '../public/assets/styles/customResponsive.css';
import '../public/assets/styles/header.css';
import '../public/assets/styles/reports.css';
import '../public/assets/fonts';
import '../public/assets/styles/tailwind_output.css';
import '../public/assets/js/newrelic'
import { updateReportEntrySynced } from './redux/actions/offlineEnhanced';
Formio.setApiUrl(AppConfig.apiUrl);
Formio.setProjectUrl(AppConfig.projectUrl);
setupAriesComponents(Formio, Components, WebformBuilder);

addLocaleData(en); // added locale for english
addLocaleData(es); // added locale for spanish

initDB(IndexedDBconfig); // creating DB for offline signatures

// Service workers registration handling
const { DEV_TOOLS, env } = config;

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then((registration) => {
      const persitRoot = JSON.parse(localStorage.getItem("persist:root"))
      const auth = persitRoot.auth
      localStorage.setItem("persist:root", JSON.stringify(auth) )
      console.log('Registration and cache cleaning successful, scope is:', registration.scope);
    })
    .catch((err) => {
      console.log('Service worker registration failed, error:', err);
    });
}


// Only init Sentry on specific environments

  Sentry.init({
    enabled: true,
    dsn: DEV_TOOLS.SENTRY_DSN,
    environment: DEV_TOOLS.SENTRY_ENV,
    logLevel: 3,
    release: version,
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications.'],
  });


ReactDOM.render(
  // Wrapper for handling errors
  <ErrorBoundary>
    {/* Wrapper for providing state to app */}
    <Provider store={store}>
      {/* Wrapper for persisting data */}
      <PersistGate persistor={persistor}>
        {/* Wrapper for routing */}
        <BrowserRouter
          getUserConfirmation={(message, callback) =>
            UserConfirmation(message, callback, store.getState().locale.lang)
          }
        >
          {/* Provider for chat */}
          <ChatProvider>
            {/* Main App Component */}
            <App />
          </ChatProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);

window.addEventListener('load', () => {
  const swUrl = `/service-worker.js`;
  navigator.serviceWorker.getRegistration(window.location.origin)
    .then( registration => {
      console.log("CURRENT REGISTRATIONS", registration)
    })
    .catch( registrationError => {
      console.log("Error", registrationError) 
    })
  
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      console.log('index.js Registration successful, scope is:', registration.scope);
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            console.log(`SUCCESSFULLY INSTALLED ${swUrl}`);
            if (caches) {
              caches.keys().then((names) => {
                for (let name of names) {
                  if(name.includes('workbox-precache-v2')){
                    console.info('deleting workbox-precache-v2', name);
                    caches.delete(name);
                  }
                }
              });
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    })
  

  navigator.serviceWorker.addEventListener('controllerchange', () => {
    console.log("CONTROLLER CHANGED")
    //window.dispatchEvent(new Event('pwa-update'))
  });

  navigator.serviceWorker.addEventListener('message', (event) => {
    if (event.data.type && event.data.type === 'sync-report-done') {
      console.log("BACKGROUND_SYNC__REPORT_SYNCED", event.data.reportId)
      store.dispatch( updateReportEntrySynced(event.data.reportId) )
    }
  })

});

serviceWorkerRegistration.register()

/*let updateFired = false;

serviceWorkerRegistration.register({
  onUpdate: () => {
    if(!updateFired) {
      window.dispatchEvent(new Event('pwa-update'));
    }
    updateFired = true;
  }
}); */