import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import config from '../../config';
import endpoints from '../../routes/endpoints';
import Button from './Button';
import * as Sentry from '@sentry/react';

const Header = () => (
  <nav className="navbar navbar-dark flex-md-nowrap fixed-top">
    <div className="header-logo">
      <a href="/" className="navbar-brand">
        <img src="img/header/Logo.svg" alt="logo" />
      </a>
    </div>
  </nav>
);
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  /* @param {error} */
  static getDerivedStateFromError() {

    // Update state so the next render will show the fallback UI.
    if (config.DEV_TOOLS.logError) {
      // log error
    }
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
  
    // You can also log the error to an error reporting service
    Sentry.withScope((scope) => {
      // Add extra data to the scope
      scope.setExtra('pathname', window.location.pathname);
      scope.setExtra('errorInfo', JSON.stringify(errorInfo));

      // Capture the exception with the updated scope
      Sentry.captureException(error);
    });
    }
  handleRetryClick() {
    // Hard refresh to clear browser cache
    window.location.reload(true);
  }
  render() {
    if (this.state.hasError) {

      return (
        <>
          {!document.getElementById('aries-header') && <Header />}
          <main
            id="scrollableDiv"
            role="main"
            className="main-container responsive-mob"
          >
            <div style={{ marginTop: '20px' }}>
              <div>
                <FormattedMessage
                  id="We're sorry, we've experienced an error. Please quit the operation and try again. If the error persists please contact us for assistance."
                  defaultMessage="We're sorry, we've experienced an error. Please quit the operation and try again. If the error persists please contact us for assistance."
                />
                <br />
                <div style={{ lineHeight: 5, display: 'flex' }}>
                  <FormattedMessage
                    id="Click here to"
                    defaultMessage="Click here to"
                  />
                  <pre style={{ fontSize: 'inherit' }}>:- </pre>
                  <a href="#" onClick={this.handleRetryClick}>
                    <Button text="Retry" color="#b72e28" />
                  </a>
                  <span style={{ margin: '0 10px' }}>
                    -
                    <FormattedMessage id="OR" defaultMessage="OR" />-
                  </span>
                  <a href={endpoints.default}>
                    <Button text=" Go back to Dashboard" />
                  </a>
                </div>
              </div>
            </div>
          </main>
        </>
      );
    }
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object.isRequired,
};
