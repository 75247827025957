import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import MyJobsLayout from '../../layouts/MyJobsLayout/MyJobsLayout';
import getMyJobList from '../../redux/actions/getMyJobList';
import { endMessageTaskPagination } from '../../utils/Constants';
import { reportCat } from '../../redux/actions/reportCategory';
import TaskModalBody from '../../layouts/CustomerHomeNewLayout/taskModalBody';
import Modal from '../../components/Modal/Modal';
import '../MissedInspectionView/MissedInspectionView.css';
import endpoints from '../../routes';
import { clearInspectionState } from '../../redux/actions/updateAnswer';
import formReportDetails from '../../redux/actions/addReportCat';
import { displayToast } from '../../_helpers/commonFunctions';
import {
  runCoachMarks,
  toggleCoachmarksVisibility,
} from '../../redux/actions/signUp';
import {
  completeJobManually,
  printJobList,
  printJobListFinished,
} from '../../redux/actions/getJobList';
import Loader from '../../components/Loader';
import coachMarks from '../../libs/constants/coachMarks';

export class MissedInspectionView extends React.Component {
  constructor(props) {
    super(props);
    this.page = 1;
    this.LIMIT = 50;
  }

  state = {
    searchText: '',
    filter: undefined,
    isCrossVisible: false,
    reportCategory: [],
    jobDateSort: 1,
    businessNameSort: undefined,
    startDate: moment().startOf('day').valueOf(),
    endDate: moment().endOf('day').valueOf(),
    isClassVisible: {},
    endMessage: endMessageTaskPagination,
    selectedTask: [],
    isTaskModal: false,
    jobsDoneManually: [],
  };

  reloadList = () => {
    this.props.getMyJobList(this.page, this.LIMIT, {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      businessNameSort: this.state.businessNameSort,
      jobDateSort: this.state.jobDateSort,
      search: this.state.searchText,
      reportCategory: this.state.reportCategory,
      jobStatus: ['Assigned'],
      onlySelf: true,
    });
  };

  applyFilter = (filter) => {
    this.page = 1;
    const reportCategory = this.appliedFilters(filter.reportFilters);
    this.setState(
      {
        reportCategory,
      },
      () => this.reloadList()
    );
  };

  // Handle Mobile Table View
  setClassVisibility = (jobId) => {
    const isClassVisible = {};
    if (Object.keys(this.state.isClassVisible[0] === jobId)) {
      isClassVisible[`${jobId}`] = !this.state.isClassVisible[`${jobId}`];
    } else {
      isClassVisible[`${jobId}`] = true;
    }
    this.setState({ isClassVisible });
  };

  setDatePeriod = (startDate, endDate) => {
    this.page = 1;
    if (endDate === null) {
      this.setState(
        {
          startDate: moment().startOf('month').valueOf(),
          endDate: moment().endOf('year').valueOf(),
        },
        () => this.reloadList()
      );
    } else {
      this.setState(
        {
          startDate: moment(startDate).valueOf(),
          endDate: moment(endDate).valueOf(),
        },
        () => this.reloadList()
      );
    }
  };

  handleStartJob = (reportData) => {
    let formData = {};
    formData = {
      typeValue: reportData.type,
      catValue: reportData.reportCategory,
      customerValue: reportData.createdFor._id,
      frequency: reportData.frequency,
      price: reportData.price,
    };
    this.props.clearInspectionState();
    this.props.formReportDetails(formData);
    displayToast('Report started successfully', 'success');
    this.props.history.push(endpoints.reportClientDetail, {
      jobId: reportData.jobId,
    });
  };

  appliedFilters = (array) => {
    const newArray = Object.keys(array).filter((item) => array[item]);
    return newArray;
  };

  setFilters = (filters) => {
    this.setState(
      {
        filter: filters,
      },
      () => this.applyFilter(this.state.filter)
    );
  };

  componentDidMount() {
    this.page = 1;
    this.reloadList();
    this.props.reportCat();
  }

  componentDidUpdate(prevProps) {
    const {
      jobsCompletedManually,
      currentCoachmarkTarget,
      isSuccess,
      jobList,
    } = this.props;
    if (
      jobsCompletedManually.success &&
      jobsCompletedManually.success !== prevProps.jobsCompletedManually.success
    ) {
      displayToast(jobsCompletedManually.success, 'success');
      let _jobsDoneManually = [...this.state.jobsDoneManually];
      _jobsDoneManually.push(jobsCompletedManually.jobDetail.jobId);
      _jobsDoneManually = Array.from(new Set(_jobsDoneManually));
      this.setState({ jobsDoneManually: [..._jobsDoneManually] });
    } else if (
      jobsCompletedManually.error &&
      jobsCompletedManually.error !== prevProps.jobsCompletedManually.error
    ) {
      displayToast(jobsCompletedManually.error, 'error');
    }
    if (
      isSuccess &&
      isSuccess !== prevProps.isSuccess &&
      !jobList.length &&
      currentCoachmarkTarget === coachMarks.inspectionReminders.startJob.target
    ) {
      this.props.toggleCoachmarksVisibility(
        null,
        null,
        coachMarks.inspectionReminders.team.target
      );
    }

    this.handleResponseForJobListDownload(prevProps);
  }

  setSearchText = (text) => {
    this.setState({ searchText: text, isCrossVisible: true });
  };

  handleOnSearch = (text) => {
    this.page = 1;
    if (text === '') {
      this.setState({ searchText: '', isCrossVisible: false }, () =>
        this.reloadList()
      );
    }
    this.reloadList();
  };

  showTaskModdal = (tasks) => {
    this.setState({
      isTaskModal: true,
      selectedTask: tasks,
    });
  };

  loadMore = () => {
    this.page += 1;
    this.reloadList();
  };

  isEndMessageVisible = () =>
    this.page > 1 || (this.props.jobList && this.props.jobList.length) === 0;

  sortData = (index) => {
    this.page = 1;
    const { businessNameSort, jobDateSort } = this.state;
    if (index === 1) {
      this.setState(
        {
          businessNameSort:
            businessNameSort === undefined ? 1 : businessNameSort * -1,
        },
        () => this.reloadList()
      );
    } else {
      this.setState(
        {
          jobDateSort: jobDateSort === undefined ? 1 : jobDateSort * -1,
        },
        () => {
          this.reloadList();
        }
      );
    }
  };

  printPage = () => {
    this.props.printJobList(
      this.page,
      this.LIMIT,
      this.state.searchText,
      this.state.status,
      this.state.reportCategory,
      this.state.jobDateSort,
      this.state.businessNameSort,
      this.state.startDate,
      this.state.endDate,
      this.state.reportType,
      true
    );
  };

  handleResponseForJobListDownload = (prevResponse) => {
    const { success, error, printData } = this.props;

    if (success && printData && printData !== prevResponse.printData) {
      displayToast(success, 'success');
      const pdfWindow = window.open(printData);
      this.props.printJobListFinished();
    } else if (error && prevResponse.error !== error) {
      displayToast(error, 'error');
    }
  };

  render() {
    return (
      <div>
        {this.props.jobsCompletedManually.isFetching && <Loader />}
        {this.state.isTaskModal ? (
          <Modal
            modalId="job-link"
            modalHeading="Tasks"
            ModalBody={TaskModalBody}
            ModalBodyProps={{
              task: this.state.selectedTask,
              inspectionDetails: { isFetching: false },
            }}
            handleClickCross={() => this.setState({ isTaskModal: false })}
          />
        ) : null}
        <MyJobsLayout
          isCrossVisible={this.state.isCrossVisible}
          searchText={this.state.searchText}
          jobList={this.props.jobList}
          loadMore={this.loadMore}
          showTaskModdal={this.showTaskModdal}
          printPage={this.printPage}
          sortData={this.sortData}
          setFilters={this.setFilters}
          setSearchText={this.setSearchText}
          hasMoreItems={this.page < this.props.pages}
          handleSearch={this.handleOnSearch}
          isfetching={this.props.isfetching}
          isClassVisible={this.state.isClassVisible}
          setClassVisibility={this.setClassVisibility}
          reportCategory={this.state.reportCategory}
          filter={this.state.filter}
          setDatePeriod={this.setDatePeriod}
          isEndMessageVisible={this.isEndMessageVisible}
          reportCategories={this.props.reportCategories}
          handleStartJob={this.handleStartJob}
          runCoachMarks={this.props.runCoachMarks}
          completeJobManually={this.props.completeJobManually}
          jobsDoneManually={this.state.jobsDoneManually}
          isReportNeeded={this.props.isReportNeeded}
          toggleCoachmarksVisibility={this.props.toggleCoachmarksVisibility}
          currentCoachmarkTarget={this.props.currentCoachmarkTarget}
          auth={this.props.auth}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  jobList: state.getJobsList.myJobData,
  printData: state.getJobsList.printData,
  success: state.getJobsList.success,
  error: state.getJobsList.error,
  pages: state.getJobsList.pages,
  isfetching: state.getJobsList.isfetching,
  isSuccess: state.getJobsList.success,
  reportCategories: state.reportCategory.reportData,
  jobsCompletedManually: state.getJobsList.jobsCompletedManually || {},
  isReportNeeded: state.auth.login.user.isReportNeeded,
  currentCoachmarkTarget: state.signUpStatus.currentTarget,
  auth: state.auth,
});

MissedInspectionView.propTypes = {
  jobList: PropTypes.array,
  isfetching: PropTypes.bool,
  pages: PropTypes.number,
  getMyJobList: PropTypes.any,
  reportCategories: PropTypes.object,
  reportCat: PropTypes.func,
  history: PropTypes.object,
  clearInspectionState: PropTypes.func,
  formReportDetails: PropTypes.func,
  runCoachMarks: PropTypes.func,
  isSuccess: PropTypes.string,
  completeJobManually: PropTypes.func,
  jobsCompletedManually: PropTypes.object,
  isReportNeeded: PropTypes.any,
  toggleCoachmarksVisibility: PropTypes.func,
  currentCoachmarkTarget: PropTypes.string,
  auth: PropTypes.object,
};

export default connect(mapStateToProps, {
  getMyJobList,
  reportCat,
  clearInspectionState,
  formReportDetails,
  runCoachMarks,
  completeJobManually,
  toggleCoachmarksVisibility,
  printJobList,
  printJobListFinished,
})(MissedInspectionView);
